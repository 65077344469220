<template>
    <Loading :isLoading="isLoading" />
    <div class="w-full h-full px-5 md:px-5 lg:px-10 pt-2 md:pt-5 lg:max-h-screen mb-5">
        <h1 class="primary-text font-semibold text-2xl md:text-2xl text-thin mt-2 mb-5 md:mb-7"> Solicitar Crédito </h1>
        <p class="font-normal mt-6">
            Para solicitar un crédito deberá completar los formularios que se mostrarán a continuación.
        </p>
        <div class="w-full">
            <router-link id="button_continue" :to="'/cuenta/credito/datos'" class="md:w-1/3 block bg-mustardDark cursor-pointer px-5 py-4 mx-auto my-8 h-14 md:h-14 rounded text-center font-normal text-xl text-white"> Solicitar </router-link>       
        </div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeMount } from 'vue';

import Loans from "@/classes/Loans.js";
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import Loading from '@/components/Loading/VueLoading.vue';
import { useRouter } from 'vue-router';

import Swal from 'sweetalert2';

export default {
    name: 'ApplyLoanComponent',
    setup(){

        let isLoading = ref(true);

        let loadClassObject = ref(null);

        let rfc = ref("");
        let clientId = ref(0);

        const router = useRouter();

        onBeforeMount(()=> {
            loadClassObject.value = new Loans();
            loadInfo();
        });

        onMounted(async () => {
            await isALoanCandidate();
            isLoading.value = false;
        });

        function loadInfo() {
            var auth = getTokenDecoden();
            
            if (auth.obj.role == "member" || auth.obj.role == "cabsa_member") {
                rfc.value = auth.obj.person.rfc;
                clientId.value = auth.obj.person.client_id;
            }
        }

        async function isALoanCandidate() {
            let isCandidate = await searchLoanCandidate();
            if(isCandidate.status && isCandidate.data != null && isCandidate.data != ''){
                return;
            }

            showMessage('info', 'Aviso', isCandidate.message); 
            router.push({ path: '/cuenta/principal' });
        }

        function showMessage(type, title, message, confirmButtonText = "OK", confirmButtonColor= "#FEB72B") {
            Swal.fire({
                title: title,
                html: message,
                icon: type,
                confirmButtonText: confirmButtonText,
                confirmButtonColor: confirmButtonColor
            });
        }

        async function searchLoanCandidate() {
            let resp = await loadClassObject.value.searchLoanCandidate(getToken(), clientId.value).then(resp => {
                return {status: true, message: resp.data.response.message, data: resp.data.response.result};
            }).catch(err => { return { status: false, message: 'Usted no candidato para un crédito', data: null } });

            return resp;
        }

        function getToken() {
            return sessionStorage.getItem('login');
        }

        return {
            isLoading
        };
    },
    components: {
        Loading
    }
}

</script>

<style scoped>
p {
    color: #485462;
}
</style>